import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { BASE_URL } from '../Constant';
import { errorToast, successToast } from '../Constant/functions';



const Contact = (propos) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [isSubmetting, setIsSubmetting] = useState(false);

    function contactReset() {
        setFirstName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setSubject('');
        setMessage('');
    }


    const onSubmit = () => {
        if (firstName === ''){
            errorToast('Nom est requis !');
            return false;
        }   
        else if (lastName === '') {
            errorToast('Prénom est requis !');
            return false;
        } 
        else if (email === '') {
            errorToast('Email est requis !');
            return false;
        }
        else if (phone === '') {
            errorToast('Téléphone est requis !');
            return false;
        }
        else if (subject === '') {
            errorToast('Sujet est requis !');
            return false;
        }
        else if (message === '') {
            errorToast('Message est requis !');
            return false;
        }
            
        if (firstName !== '' && lastName !== '' && email !== '' && phone !== '' && subject !== '' && message !== '') {
            setIsSubmetting(true)
            const body = {
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phone,
                subject: subject,
                message: message,
                active: true
            };  
            axios.post(BASE_URL + `contact/`, body)
            .then((response) => {
                if (response.status === 201) {
                    successToast('Votre message a bien été envoyé, nous vous répondrons dans les plus brefs délais.')
                    contactReset()
                    setIsSubmetting(false)
                }
            }).catch(err => {
                errorToast("Oups... quelque chose s'est mal passé")
                setIsSubmetting(false)
            });

        }
    }

    return (
        <div>
            <div className="breadcrumb-area gradient-bg text-light text-center">
                <div className="fixed-bg" style={{ backgroundImage: "url('assets/img/shape/1.png')" }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <h1>Contact</h1>
                            <ul className="breadcrumb">
                                <li><Link to={'/'}><i className="fas fa-home"></i> Accueil</Link></li>
                                <li className="active">Contact</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="contact-area default-padding">
                <div className="container">
                    <div className="contact-items">
                        <div className="row">
                            <div className="col-lg-4 left-item">
                                <div className="info-items">
                                    <div className="item">
                                        <div className="icon">
                                            <i className="fas fa-map-marked-alt"></i>
                                        </div>
                                        <div className="info">
                                            <h5>Emplacement</h5>
                                            <p>
                                                Avenue Maldom Bada Abbas, Immeuble BSIC 2eme Etage N'Djamena - Tchad
                                            </p>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="icon">
                                            <i className="fas fa-phone"></i>
                                        </div>
                                        <div className="info">
                                            <h5>Passer un coup de téléphone</h5>
                                            <p>
                                                +(235) 63 61 44 79
                                            </p>
                                        </div>
                                    </div>

                                    <div className="item">
                                        <div className="icon">
                                            <i className="fas fa-envelope-open"></i>
                                        </div>
                                        <div className="info">
                                            <h5>Envoyer un courrier</h5>
                                            <p>
                                                info@opendata.wenaklabs.org
                                            </p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-8 right-item">
                                <h2>Nous aimerions avoir de vos nouvelles à tout moment</h2>
                                <form className="contact-form">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input value={firstName} rows={2} onChange={(e) => setFirstName(e.target.value)} className="form-control" placeholder="Nom *" type="text" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input value={lastName} rows={2} onChange={(e) => setLastName(e.target.value)} className="form-control" placeholder="Prénom *" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input value={email} rows={2} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email *" type="email" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group">
                                                <input value={phone} rows={2} onChange={(e) => setPhone(e.target.value)} className="form-control" placeholder="Téléphone *" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group">
                                                <input value={subject} rows={2} onChange={(e) => setSubject(e.target.value)} className="form-control" placeholder="Sujet *" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="form-group comments">
                                                <textarea value={message} rows={2} onChange={(e) => setMessage(e.target.value)} className="form-control" placeholder="Écris quelque chose *"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <button type="button" disabled={isSubmetting} onClick={onSubmit}>
                                                Envoyer le message <i className="fa fa-paper-plane"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className="maps-area">
                <div className="google-maps">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d14767.262289338461!2d70.79414485000001!3d22.284975!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1424308883981"></iframe>
                </div>
            </div> */}
        </div>
    )
}

export default Contact