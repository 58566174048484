import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { useParams } from 'react-router-dom';
import $ from 'jquery';

import './projects.css';
const Projects = () => {
    const { filter } = useParams();
    const [loading, setLoading] = useState(true);
    const [projects, setProjects] = useState([]);

    const getDate = (timestamps) => {
        var d = new Date(parseInt(timestamps) * 86400 * 1000);
        return d.toLocaleDateString()
    }

    const getProjects = () => {
        let url = ""
        switch (filter) {
            case 'active':
                url = "https://d-portal.org/q?from=act,country&limit=-1&select=title,aid,funder_ref,commitment,spend,reporting,reporting_ref,day_start,day_end,status_code&distincton=aid&country_code=TD&view=active&day_end_gteq=2023-07-22%7CNULL&day_start_lteq=2023-07-22&day_length_not_null=1&orderby=day_end&_=1690030533648";
                break;
            case 'termines':
                url = "https://d-portal.org/q?from=act,country&limit=-1&select=title,aid,funder_ref,commitment,spend,reporting,reporting_ref,day_start,day_end,status_code&distincton=aid&country_code=TD&view=ended&day_end_lt=2023-07-22&day_length_not_null=1&orderby=day_end-&_=1690030312987";
                break;
            case 'avenir':
                url = "https://d-portal.org/q?from=act,country&limit=-1&select=title,aid,funder_ref,commitment,spend,reporting,reporting_ref,day_start,day_end,status_code&distincton=aid&country_code=TD&view=planned&day_start_gt=2023-07-22&day_length_not_null=1&orderby=day_start&_=1690030650595"
                break;
            case 'autres':
                url = "https://d-portal.org/q?from=act,country&limit=-1&select=title,aid,funder_ref,commitment,spend,reporting,reporting_ref,day_start,day_end,status_code&distincton=aid&country_code=TD&view=missing&day_length_null=1&_=1690030749707"
                break;
            default:
                url = "https://d-portal.org/q?from=act,country&limit=-1&select=title,aid,funder_ref,commitment,spend,reporting,reporting_ref,day_start,day_end,status_code&distincton=aid&country_code=TD&view=list_activities&_=1690030817584"
                break;
        }
        axios.get(url)
            .then(res => {
                let { rows = [] } = res.data;
                if (rows.length > 0) {
                    let data = rows.map(item => ({ ...item, day_start: getDate(item.day_start), day_end: item.day_end ? getDate(item.day_end) : null }));
                    setProjects(data);
                }
            })
            .catch(err => console.log("Error", err.message))
            .finally(() => setLoading(false))
    }

    const showMore = () => {
        console.log("it a test");
        $('.blog-item-box .project-item:hidden').slice(0, 2).show();
        if ($('.blog-item-box .project-item').length === $('.blog-item-box .project-item:visible').length) {
            $('show-more').hide();
        }
    }


    useEffect(() => {
        getProjects()
    })

    // TRUNCATE LONG TEXT 
    const truncate = (input) =>
        input?.length > 180 ? `${input.substring(0, 180)}...` : input;

    let pageTitle = "Tous les projets"

    switch (filter) {
        case 'active':
            pageTitle = "Projets Actifs";
            break;
        case 'termines':
            pageTitle = "Projets Terminés";
            break;
        case 'avenir':
            pageTitle = 'Projets Avenirs';
            break;
        case 'autres':
            pageTitle="Autres projets";
            break;
        default:
            break;
    }

    return (
        <div>
            <div className="breadcrumb-area gradient-bg text-light text-center">
                <div className="fixed-bg" style={{ backgroundImage: "url('assets/img/shape/1.png')" }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <h1>{pageTitle}</h1>
                            <ul className="breadcrumb">
                                <li><Link to="/"><i className="fas fa-home"></i> Accueil</Link></li>
                                <li className="active">{'Liste projets au Tchad'}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog-area full-blog right-sidebar full-blog default-padding">
                <div className="container">
                    <div className="blog-items">
                        <div className="row">
                            <div className="blog-content col-lg-12 col-md-12">
                                {loading &&
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                }
                                {!loading && projects?.length === 0 &&
                                    <h4> {'Aucune données pour le moment'}</h4>
                                }
                                <div className="blog-item-box">
                                    {projects?.map((data, index) => {
                                        return (
                                            <div className="single-item project-item" key={'data-' + index}>
                                                <div className="item">
                                                    <div className="info">
                                                        <div className="row align-items-center">
                                                            <div className="col-lg-12">
                                                                <h4>
                                                                    <a target='_blank' rel="noreferrer" href={`https://d-portal.org/ctrack.html?country_code=TD#view=act&aid=${data.aid}`}>
                                                                        {truncate(data.title)}
                                                                    </a>
                                                                </h4>
                                                                <div className="meta">
                                                                    <ul className='project-meta'>
                                                                        <li><b>{'Financement'}: </b><span>{data.commitment === 0 ? data.spend : data.commitment} USD</span><br /></li>
                                                                        <li><b>{'Reçu'}: </b><span>{data.spend} USD</span><br /></li>
                                                                        <li><b>{'Date de commencement'}: </b><span>{data.day_start}</span><br /></li>
                                                                        <li><b>{'Date de fin'}: </b><span>{data.day_end ?? "Non définie"}</span><br /></li>
                                                                    </ul>
                                                                </div>
                                                                <p>
                                                                    {'Rapporté par ' + data.reporting}
                                                                </p><hr />
                                                                <div className="meta-title">
                                                                    <a target='_blank' rel="noreferrer" href={`https://d-portal.org/ctrack.html?country_code=TD#view=act&aid=${data.aid}`} className='mx-5 my-3 fs-6 btn btn-primary rounded'>
                                                                        <small>Voir plus</small>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                    }
                                    {projects.length > 8 &&
                                        <div className='col-12 my-2'>
                                            <button onClick={() => showMore()} className='show-more btn circle btn-md btn-standard border-standard shape wow fadeInUp'>
                                                <span style={{ textTransform: 'none' }}>
                                                    {'Lire plus '}
                                                </span>
                                                {loading &&
                                                    <div className="spinner-border text-primary" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                }
                                            </button>
                                        </div>
                                        }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
export default Projects