import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { AUTH_BASE_URL, BASE_URL } from '../Constant';
import { errorToast, successToast } from '../Constant/functions';



const RequestCreate = () => {
    const [categories, setCategories] = useState([]);
    const [continents, setContinents] = useState([]);
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([]);

    const [isSubmetting, setIsSubmetting] = useState(false);
    const [subject, setSubject] = useState('');
    const [category, setCategory] = useState('');
    const [continent, setContinent] = useState('');
    const [country, setCountry] = useState('');
    const [hideCountry, setHideCountry] = useState(false);
    const [region, setRegion] = useState('');
    const [hideRegion, setHideRegion] = useState(false);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [description, setDescription] = useState('');
    const string = localStorage.getItem('auth');
    const user = string ? JSON.parse(string) : {};
    let requested_by = user?.id;
    if (!requested_by)
        window.location = `/authentication`


    useEffect(() => {
        getCategories()
        getContinents()
    }, []);


    const getCategories = () => {
        axios.get(BASE_URL + `data_category/`).then(res => setCategories(res.data));
    }

    const getContinents = () => {
        axios.get(AUTH_BASE_URL + `api/continent/`).then(res => setContinents(res.data));
    }

    const getCountries = (e) => {
        if (e !== '') {
            axios.get(AUTH_BASE_URL + `api/continent/countries/${e}`).then(res => {
                setHideCountry(true)
                setContinent(e)
                setCountries(res.data)
            });
        } else {
            setContinent('')
            setHideCountry(false)
        }
    }
    const getRegions = (e) => {
        if (e !== '') {
            axios.get(AUTH_BASE_URL + `api/country/regions/${e}`).then(res => {
                setHideRegion(true)
                setCountry(e)
                setRegions(res.data)
            });
        } else {
            setCountry('')
            setHideRegion(false)
        }


    }

    function resetForm() {
        setHideCountry(false);
        setHideRegion(false);
        setSubject('');
        setCategory('');
        setContinent('');
        setCountry('');
        setRegion('');
        setDateFrom('');
        setDateTo('');
        setDescription('');
    }

    const onSubmit = () => {
        if (subject === '') {
            errorToast('Sujet est requis !');
            return false;
        }
        else if (category === '') {
            errorToast('Catégorie est requies !');
            return false;
        } 
        else if (continent === '') {
            errorToast('Continent est requis !');
            return false;
        }
        else if (dateFrom === '') {
            errorToast('Dater de est requise!')
            return false;
        }
        else if (dateTo === '') {
            errorToast('Date de est requise!')
            return false;
        }
        else if (dateTo <= dateFrom) {
            errorToast('Date de ne peut pas être égale ou inferieur a Dater de!')
            return false;
        }
        else if (description === '') {
            errorToast('Description est requies !');
            return false;
        }

        if (subject !== '' && category !== '' && continent !== '' && dateFrom !== '' && dateTo !== '') {
            setIsSubmetting(true)
            const body = {
                requested_by: requested_by,
                subject: subject,
                category: category,
                continent: continent,
                country: country,
                region: region,
                date_from: dateFrom,
                date_to: dateTo,
                description: description
            };
            let token = user.tokens.access
            let config = {
                headers: {'Authorization': `Bearer ${token}`}
            }
            axios.post(BASE_URL + `request/`, body, config)
                .then((response) => {
                    if (response.status === 201) {
                        successToast("Votre demande a été envoyée avec succès, \nnous vous répondrons via votre email")
                        resetForm()
                        setIsSubmetting(false)
                    }
                }).catch(err => {
                    if (err.response.status === 401) {
                        errorToast("Votre session a expiré,\nEssayez de vous reconnecter pour continuer")
                        setIsSubmetting(false)
                        return false;
                    }
                    errorToast("Oups... quelque chose s'est mal passé")
                    setIsSubmetting(false)
                });

        }
    }

    return (
        <div>
            <div className="breadcrumb-area gradient-bg text-light text-center">
                <div className="fixed-bg" style={{ backgroundImage: "url('assets/img/shape/1.png')" }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <h1>Faire une demande</h1>
                            <ul className="breadcrumb">
                                <li><Link to={'/'}><i className="fas fa-home"></i> Accueil</Link></li>
                                <li className="active">Faire une demande</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="contact-area default-padding">
                <div className="container">
                    <div className="contact-items">
                        <div className="row">
                            <div className="col-lg-12 right-item">
                                <form className="contact-form">
                                    <div className="row">
                                        <div className="col-lg-12 mb-3">
                                            <div className="form-group">
                                                <label>Sujet</label>
                                                <input value={subject} onChange={(e) => setSubject(e.target.value)} className="form-control" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlSelect1">Catégorie</label>
                                                <select className="form-control" id="exampleFormControlSelect1" defaultValue="0" onChange={e => setCategory(e.target.value)}>
                                                    <option value={''}>-----------</option>
                                                    {categories.map((category_item, index) => (
                                                        <option value={category_item.id} key={'category-' + index}>{category_item.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-3">
                                            <div className="form-group">
                                                <label htmlFor="exampleFormControlSelect1">Continent</label>
                                                <select className="form-control" id="exampleFormControlSelect1" defaultValue="0" onClick={e => getCountries(e.target.value)}>
                                                    <option value={''}>-----------</option>
                                                    {continents.map((continent_item, index) => (
                                                        <option value={continent_item.id} key={'continent-' + index}>{continent_item.name}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {hideCountry &&
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label htmlFor="exampleFormControlSelect1">Pays</label>
                                                    <select className="form-control" id="exampleFormControlSelect1" defaultValue="0" onChange={e => getRegions(e.target.value)}>
                                                        <option value={''}>-----------</option>
                                                        {countries.map((country_item, index) => (
                                                            <option value={country_item.id} key={'country-' + index}>{country_item.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        }
                                        {hideRegion &&
                                            <div className="col-lg-6 mb-3">
                                                <div className="form-group">
                                                    <label htmlFor="exampleFormControlSelect1">Région</label>
                                                    <select className="form-control" id="exampleFormControlSelect1" defaultValue="0" onChange={e => setRegion(e.target.value)}>
                                                        <option value={''}>-----------</option>
                                                        {regions.map((region_item, index) => (
                                                            <option value={region_item.id} key={'region-' + index}>{region_item.name}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 mb-3">
                                            <div className="form-group">
                                                <label>Dater de</label>
                                                <input value={dateFrom} onChange={(e) => setDateFrom(e.target.value)} className="form-control" type="date" />
                                            </div>
                                        </div>
                                        <div className="col-lg-6 mb-3">
                                            <div className="form-group">
                                                <label>Date de</label>
                                                <input value={dateTo} onChange={(e) => setDateTo(e.target.value)} className="form-control" type="date" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <label>Description</label>
                                            <div className="form-group comments">
                                                <textarea value={description} rows={2} onChange={(e) => setDescription(e.target.value)} className="form-control"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <button type="button" disabled={isSubmetting} onClick={onSubmit}>
                                                Faire une demande <i className="fa fa-paper-plane"></i>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequestCreate