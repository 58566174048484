import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const successToast = (message)=>{
    toast.success(message);
}

export const errorToast = (message)=>{
    toast.error(message);
}

export const warningToast = (message)=>{
    toast.warning(message);
}

export const infoToast = (message)=>{
    toast.info(message);
}

// export const config = ()=>{
// 	const string = localStorage.getItem('auth');
//     const user   = string ? JSON.parse(string) : {};
//     let requested_by = user.id;
//     if (!requested_by){
//         window.location = `/authentication`
//     } else {
//     	let token = user.tokens.access
//     	let config = {
//             headers: {'Authorization': `Bearer ${token}`}
//         }
//     }
// }
