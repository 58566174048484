import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import Moment from 'moment';
import { BASE_URL } from '../Constant';
import { errorToast } from '../Constant/functions';


const Blog = () => {
    const [blogcategories, setBlogCategorie] = useState([]);
    const [blogs, setBlog] = useState([]);
    const [q, setQ] = useState('');

    const getBlogCategories = () => {
        axios.get(BASE_URL + `article_category/`)
            .then((res) => {
                if (res.status === 200)
                    setBlogCategorie(res.data);
            })
    }

    const getBlogs = () => {
        axios.get(BASE_URL + `article/`)
            .then((res) => {
                if (res.status === 200)
                    setBlog(res.data);
            })
    }

    const getBlogFilter = (slug) => {
        axios.get(BASE_URL + `article/filter/${slug}`)
            .then((res) => {
                if (res.status === 200)
                    setBlog(res.data);
            })
    }

    
    useEffect(() => {
        getBlogCategories();
        getBlogs();
    }, [])




    const onSubmit = () => {
        if (q === '')
            return errorToast('Entrer quelque chose!');
        axios.get(BASE_URL + `article/search/${q}`)
        .then((res) => {
            if (res.status === 200)
                setBlog(res.data);
        })
    }

    // TRUNCATE LONG TEXT 
    const truncate = (input) =>
        input?.length > 300 ? `${input.substring(0, 254)}...` : input;

    return (

        <div>
            <div className="breadcrumb-area gradient-bg text-light text-center">
                <div className="fixed-bg" style={{ backgroundImage: "url('assets/img/shape/1.png')" }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <h1>Article</h1>
                            <ul className="breadcrumb">
                                <li><Link to={'/'}><i className="fas fa-home"></i> Accueil</Link></li>
                                <li className="active">Article</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="blog-area full-blog right-sidebar full-blog default-padding">
                <div className="container">
                    <div className="blog-items">
                        <div className="row">
                            <div className="blog-content col-lg-8 col-md-12">
                                <div className="blog-item-box">

                                    {blogs.map((blog, index) => {
                                        return (
                                            <div className="single-item" key={'blog-'+index}>
                                                <div className="item">
                                                    <div className="thumb">
                                                        <Link to={`/blog/${blog.slug}`}>
                                                            <img src={blog.photo} alt="Thumb" />
                                                            <div className="date">
                                                            {Moment(blog.timestamp).format('d MMM')} <strong>{Moment(blog.timestamp).format('YYYY')}</strong>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                    <div className="info">
                                                        <div className="meta">
                                                            <ul>
                                                                <li><b>Catégorie: </b><Link to={'#'}>{blog.category_name}</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <h4>
                                                            <Link to={`/blog/${blog.slug}`}>{blog.name}</Link>
                                                        </h4>
                                                        <p> {truncate(blog.description)}

                                                        </p>
                                                        <Link className="btn-simple" to={`/blog/${blog.slug}`}><i className="fas fa-angle-right"></i> Read More</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                    }
                                </div>
                            </div>

                            <div className="sidebar col-lg-4 col-md-12">
                                <aside>
                                    {/* <div className="sidebar-item search">
                                        <div className="sidebar-info">
                                            <form>
                                                <input type="text" value={q} onChange={(e) => setQ(e.target.value)} className="form-control" />
                                                <button type="button"><i className="fas fa-search"></i></button>
                                            </form>
                                        </div>
                                    </div> */}
                                    <div className="sidebar-item category">
                                        <div className="title">
                                            <h4>Catégories</h4>
                                        </div>
                                        <div className="sidebar-info">
                                            <ul>
                                                {blogcategories.map((category, index) => {
                                                    return (
                                                        <li key={'cat-'+index}>
                                                            <a onClick={() => getBlogFilter(category.slug)}>{category.name} <span>{category.total_article}</span></a>
                                                        </li>
                                                    );
                                                })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                    {/* <div className="sidebar-item recent-post">
                                        <div className="title">
                                            <h4>Post récent</h4>
                                        </div>
                                        <ul>
                                            <li>
                                                <div className="thumb">
                                                    <a href="#">
                                                        <img src="assets/img/800x800.png" alt="Thumb" />
                                                    </a>
                                                </div>
                                                <div className="info">
                                                    <a href="#">Participate in staff meetingness manage dedicated</a>
                                                    <div className="meta-title">
                                                        <span className="post-date"><i className="fas fa-clock"></i> 12 Feb, 2020</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="thumb">
                                                    <a href="#">
                                                        <img src="assets/img/800x800.png" alt="Thumb" />
                                                    </a>
                                                </div>
                                                <div className="info">
                                                    <a href="#">Future Plan & Strategy for Consutruction </a>
                                                    <div className="meta-title">
                                                        <span className="post-date"><i className="fas fa-clock"></i> 05 Jul, 2019</span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="thumb">
                                                    <a href="#">
                                                        <img src="assets/img/800x800.png" alt="Thumb" />
                                                    </a>
                                                </div>
                                                <div className="info">
                                                    <a href="#">Melancholy particular devonshire alteration</a>
                                                    <div className="meta-title">
                                                        <span className="post-date"><i className="fas fa-clock"></i> 29 Aug, 2020</span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div> */}

                                    
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Blog