import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import Moment from 'moment';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../Constant';


const DataDetail = () => {
    const { slug } = useParams();
    const [data, setData] = useState({});

    const getData = (slug) => {
        axios.get(BASE_URL + `data/${slug}`)
            .then((res) => {
                if (res.status === 200)
                    setData(res.data);
            })
    }

    const getFileExtension = (path = "") => {
        let length = path?.split('/').length,
            name = path?.split('/')[length - 1];
        let length2 = name?.split('.').length,
            ext = name?.split('.')[length2 - 1];
        console.log("file name", name, "extension", ext);
        return ext;
    }

    useEffect(() => {
        getData(slug);
    }, [slug])

    return (
        <div>
            <div className="breadcrumb-area gradient-bg text-light text-center">
                <div className="fixed-bg" style={{ backgroundImage: "url('assets/img/shape/1.png')" }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <h1>{data?.name}</h1>
                            <ul className="breadcrumb">
                                <li><Link to="/"><i className="fas fa-home"></i> Accueil</Link></li>
                                <li><Link to={"/category"}>Catégories</Link></li>
                                <li className="active">Detail</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>



            <div className="services-single-area default-padding faq-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 services-content">
                            <img src={data.photo} alt={data.name} />
                            <h2>{data.name}</h2>
                            <p>{data.description}</p>
                        </div>

                        <div className="col-lg-4 services-sidebar">
                            <aside>
                                <div className="sidebar-item">
                                    <h4 className="sidebar-title">Informations</h4>
                                    <ul>
                                        <li>
                                            <span><b>Catégorie:</b><em> {data.category_name}</em></span>
                                        </li>
                                        <li>
                                            <span><b>Identification:</b><em> {data.identifiant}</em></span>
                                        </li>
                                        <li className="">
                                            {data.universal && <span><b>Global:</b> <i className="fas fa-check text-success"></i></span>}
                                        </li>
                                        <li className="">
                                            {data.continent && <span><b>Continent:</b><em> {data.continent_name}</em></span>}
                                        </li>
                                        <li className="">
                                            {data.country && <span><b>Pays:</b><em> {data.country_name}</em></span>}
                                        </li>
                                        <li className="">
                                            {data.region && <span><b>Region:</b><em> {data.region_name}</em></span>}
                                        </li>
                                        <li>
                                            <span><b>Licence:</b><em> {data.licence}</em></span>
                                        </li>
                                        <li>
                                            <span><b>Année:</b><em> {Moment(data.date_from).format('YYYY')} - {Moment(data.date_to).format('YYYY')}</em></span>
                                        </li>
                                        <li>
                                            <span><b> Télécharger :</b><em> {data.download} fois</em></span>
                                        </li>
                                        <li>
                                            <span><b> Vue :</b><em> {data.view} fois</em></span>
                                        </li>
                                        <li>
                                            <span><b>Créée le :</b><em> {Moment(data.timestamp).format('d MMM YYYY')}</em></span>
                                        </li>
                                    </ul>
                                </div>

                                <div className="sidebar-item">
                                    <h4 className="sidebar-title">Fichier(s) de données</h4>
                                    {data.documents?.map((document, index) => {
                                        const extension = getFileExtension(document.file);
                                        return (
                                            <a key={'doc-' + index} className="btn-simple m-2" target='_blank' href={document.file} rel="noopener noreferrer" download><i className="fas fa-file-download"></i> Télécharger en <span className='text-uppercase btn btn-success'>{extension ?? "---"}</span></a>
                                        );
                                    })
                                    }
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DataDetail