import React, { Component } from 'react'
import { Link } from "react-router-dom";

export class About extends Component {
    render() {
        return (
            <div>
                <div className="breadcrumb-area gradient-bg text-light text-center">
                    <div className="fixed-bg" style={{ backgroundImage: "url('assets/img/shape/1.png')" }}></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 offset-lg-2">
                                <h1>À propos</h1>
                                <ul className="breadcrumb">
                                <li><Link to={'/'}><i className="fas fa-home"></i> Accueil</Link></li>
                                    <li className="active">À propos</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="about-area bg-gray overflow-hidden rectangular-shape default-padding">
                    <div className="container">
                        <div className="about-items choseus-items right-thumb">
                            <div className="row align-center">
                                <div className="col-lg-12">
                                    <div className="info wow fadeInLeft">
                                        {/* <h2>We provide big data analytics Techniques & Data Solutions</h2> */}
                                        <p>
                                        Openstat Tchad est une plateforme développée par WenakLabs dans le but de promouvoir l'ouverture des données et le gouvernement ouvert au Tchad. Cette plateforme permet aux citoyens, organisations de la société civile et aux entreprises d'accéder à des données publiques de manière transparente, accessible et facilement compréhensible. Elle permet également aux utilisateurs de participer activement à la prise de décision et d'influencer la politique publique.
                                        </p>
                                        <h4>Fonctionnalités principales :</h4>
                                        <ul>
                                            <li>
                                                <span><i className="ti-check"></i> 
                                                Accès à des données publiques : Openstat Tchad permet aux utilisateurs d'accéder à des données publiques sur différents sujets tels que l'économie, la santé, l'éducation, l'environnement, etc. Les données sont présentées de manière facile à comprendre grâce à des visualisations graphiques et des tableaux.
                                                </span>
                                            </li>
                                            <li>
                                                <span><i className="ti-check"></i> 
                                                Contributions des utilisateurs : les utilisateurs peuvent soumettre des données et des informations à la plateforme pour enrichir la base de données existante. Ils peuvent également participer à des enquêtes et des sondages pour exprimer leur opinion sur différents sujets.
                                                </span>
                                            </li>
                                            <li>
                                                <span><i className="ti-check"></i> 
                                                Suivi des performances des institutions publiques : Openstat Tchad permet aux citoyens de suivre les performances des institutions publiques telles que les ministères, les départements et les agences gouvernementales. Les données sur les dépenses publiques, les projets gouvernementaux et les politiques publiques sont facilement accessibles sur la plateforme.
                                                </span>
                                            </li>
                                            <li>
                                                <span><i className="ti-check"></i> 
                                                Accès aux informations sur les services publics : la plateforme fournit des informations sur les services publics tels que les soins de santé, l'éducation, les transports, etc. Les utilisateurs peuvent accéder aux horaires des services, aux emplacements et aux coûts, entre autres informations pertinentes.
                                                </span>
                                            </li>
                                        </ul>
                                        <p className='mt-3'>
                                        Openstat Tchad est une plateforme conviviale et facile à utiliser. Elle permet aux citoyens de participer activement à la prise de décision et de surveiller la performance des institutions publiques. Cette initiative contribue à promouvoir la transparence, la responsabilité et la participation citoyenne dans la gouvernance du Tchad.
                                        </p>
                                    </div>
                                </div>
                                {/* <div className="col-lg-4">
                                    <div className="thumb wow fadeInUp" data-wow-delay="0.5s">
                                        <img src="assets/img/illustration/9.png" alt="Thumb" />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default About 