import React, { Component } from 'react'

export class Footer extends Component {
    render() {
        return (
            <div>
                <footer className="bg-dark text-light">
                    <div className="container">
                        <div className="f-items default-padding">
                            <div className="row">
                                <div className="equal-height col-lg-4 col-md-6 item">
                                    <div className="f-item about">
                                        <img src="assets/img/logo-light.png" alt="Openstat Tchad" />
                                        <p>
                                            Openstat Tchad est une plateforme développée par WenakLabs dans le but de promouvoir l'ouverture des données et le gouvernement ouvert au Tchad. Cette plateforme permet aux citoyens, organisations de la société civile et aux entreprises d'accéder à des données publiques de manière transparente, accessible et facilement compréhensible.
                                        </p>
                                    </div>
                                </div>

                                <div className="equal-height col-lg-4 col-md-6 item">
                                    <div className="f-item link">
                                        <h4 className="widget-title">Liens utiles</h4>
                                        <ul>
                                            <li>
                                                <a href="/category">Catégorie</a>
                                            </li>
                                            <li>
                                                <a href="/blog">Article</a>
                                            </li>
                                            <li>
                                                <a href="/request">Demande</a>
                                            </li>
                                            <li>
                                                <a href="/about">About Us</a>
                                            </li>
                                            <li>
                                                <a href="/contact">Contact</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                               

                                <div className="equal-height col-lg-4 col-md-6 item">
                                    <div className="f-item contact">
                                        <h4 className="widget-title">Informations de contact</h4>
                                        <p>
                                            <b>Adresse: </b> Avenue Maldom Bada Abbas, Immeuble BSIC 2eme Etage N'Djamena - Tchad
                                        </p>
                                        <div className="address">
                                            <ul>
                                                <li>
                                                    <strong>Email:</strong> info@opendata.wenaklabs.org
                                                </li>
                                                <li>
                                                    <strong>Contact:</strong> +(235) 63 61 44 79
                                                </li>
                                            </ul>
                                        </div>
                                        <ul className="social">
                                            <li className="facebook">
                                                <a href="#"><i className="fab fa-facebook-f"></i></a>
                                            </li>
                                            <li className="twitter">
                                                <a href="#"><i className="fab fa-twitter"></i></a>
                                            </li>
                                            <li className="youtube">
                                                <a href="#"><i className="fab fa-youtube"></i></a>
                                            </li>
                                            <li className="instagram">
                                                <a href="#"><i className="fab fa-instagram"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-bottom">
                            <div className="row">
                                <div className="col-lg-6">
                                    <p>&copy; Copyright 2023. Tous droits réservés par <a href="#">Openstat Tchad</a></p>
                                </div>
                                <div className="col-lg-6 text-right link">
                                    <ul>
                                        <li>
                                            <a href="#">Termes</a>
                                        </li>
                                        <li>
                                            <a href="#">Confidentialité</a>
                                        </li>
                                        <li>
                                            <a href="#">Soutien</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer-shape" style={{backgroundImage: "url('assets/img/shape/1.svg')"}}></div>

                </footer>
            </div>
        )
    }
}

export default Footer 