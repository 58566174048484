import React, { Component } from 'react'
import { errorToast, successToast } from '../Constant/functions';
import axios from 'axios';
import { AUTH_BASE_URL } from '../Constant';

export class Header extends Component {
    constructor() {
        super();
        let logged = false
        let auth = localStorage.getItem('auth');
        if (!auth || auth === null)
            logged = false;
        auth = JSON.parse(auth);
        if (auth && auth?.id && auth !== null)
            logged = true;

        this.state = { logged: logged };
    }

    componentDidMount() {
        let auth = localStorage.getItem('auth');
        if (!auth || auth === null)
            this.setState({ logged: false });
        auth = JSON.parse(auth);
        if (auth && auth.id && auth !== null)
            this.setState({ logged: true });
        console.log('auth', auth, this.state.logged);
    }


    logout = (e) => {
        e.preventDefault();
        // Star checking token
        let state = localStorage.getItem('auth');
        state = JSON.parse(state);
        let { access = '', refresh } = state.tokens;
        console.log("access token", access);
        if (access !== '' && access !== null) {
            axios.post(AUTH_BASE_URL + `logout/`, { auth_token: access, refresh: refresh }, {
                headers: {
                    Authorization: `Bearer ${access}`,
                }
            })
                .then((res) => {
                    if (res.status === 205) {
                        localStorage.setItem('auth', null);
                        successToast('Deconnecter avec succès !');
                        window.location = '/';
                    }
                })
                .catch(err => {
                    errorToast('Oups! une erreur s\'est produite, réessayer SVP!');
                })

        }

    }


    render() {
        return (
            <div>

                <header id="home">


                    <nav className="navbar navbar-default attr-border navbar-fixed white no-background bootsnav">


                        <div className="container-full">
                            <div className="row">
                                <div className="top-search">
                                    <div className="input-group">
                                        <form action="#">
                                            <input type="text" name="text" className="form-control" placeholder="Search" />
                                            <button type="submit">
                                                <i className="ti-search"></i>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="container-full">


                            {/* <div className="attr-nav">
                                <ul>
                                    <li className="search"><a href="#"><i className="ti-search"></i></a></li>
                                    <li className="side-menu"><a href="#"><i className="ti-menu-alt"></i></a></li>
                                </ul>
                            </div> */}



                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                                    <i className="fa fa-bars"></i>
                                </button>
                                <a className="navbar-brand" href="/">
                                    <img src="assets/img/logo-light.png" className="logo logo-display" alt="Openstat Tchad" />
                                    <img src="assets/img/logo.png" className="logo logo-scrolled" alt="Openstat Tchad" />
                                </a>
                            </div>

                            <div className="collapse navbar-collapse align-items-center" id="navbar-menu">
                                <ul className="nav navbar-nav navbar-right" data-in="fadeInDown" data-out="fadeOutUp">
                                    <li>
                                        <a href="/">Accueil</a>
                                    </li>

                                    <li>
                                        <a href="/about">À propos</a>
                                    </li>

                                    <li>
                                        <a href="/category">Catégories</a>
                                    </li>

                                    <li>
                                        <a href="/request/create">Faire une demande</a>
                                    </li>

                                    <li>
                                        <a href="/request">Requêtes</a>
                                    </li>

                                    <li>
                                        <a href="/contact">Contact</a>
                                    </li>
                                    {this.state.logged === false &&
                                        <li>
                                            <a href="/authentication">Se connecter</a>
                                        </li>
                                    }
                                    {this.state.logged === true &&
                                        <li className='pt-0'>
                                            <a href="#" onClick={(event) => this.logout(event)}>
                                                <button className='btn btn-danger my-auto m-0'>
                                                    Deconnexion
                                                </button>
                                            </a>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </div>

                        <div className="side">
                            <a href="#" className="close-side"><i className="ti-close"></i></a>
                            <div className="widget">
                                <h4 className="title">Informations de contact</h4>
                                <ul className="contact">
                                    <li>
                                        <div className="icon">
                                            <i className="flaticon-email"></i>
                                        </div>
                                        <div className="info">
                                            <span>Email</span> Info@gmail.com
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="flaticon-call-1"></i>
                                        </div>
                                        <div className="info">
                                            <span>Téléphone</span> +123 456 7890
                                        </div>
                                    </li>
                                    <li>
                                        <div className="icon">
                                            <i className="flaticon-countdown"></i>
                                        </div>
                                        <div className="info">
                                            <span>Heures de travail</span> Sat - Wed : 8:00 - 4:00
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className="widget">
                                <h4 className="title">Liens supplémentaires</h4>
                                <ul>
                                    <li><a href="/about">À propos</a></li>
                                    <li><a href="/blog">Article</a></li>
                                    <li><a href="#">Connexion</a></li>
                                    <li><a href="#">Enregistrer</a></li>
                                </ul>
                            </div>
                            <div className="widget social">
                                <h4 className="title">Connecte-toi avec nous</h4>
                                <ul className="link">
                                    <li className="facebook"><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li className="twitter"><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li className="pinterest"><a href="#"><i className="fab fa-pinterest"></i></a></li>
                                    <li className="dribbble"><a href="#"><i className="fab fa-dribbble"></i></a></li>
                                </ul>
                            </div>
                        </div>

                    </nav>

                </header>
            </div>
        )
    }
}

export default Header 