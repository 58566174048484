import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { BASE_URL } from '../Constant';
import { errorToast } from '../Constant/functions';

const Home = () => {
    const [categories, setCategory] = useState([]);
    const [testimonies, setTestimony] = useState([]);
    const [data, setData] = useState([]);
    // const [stats, setStats] = useState([]);
    const [loadingC, setLoadingC] = useState(true);
    const [loadingT, setLoadingT] = useState(true);
    const [loadingS, setLoadingS] = useState(true);

    const getCategories = () => {
        axios.get(BASE_URL + `data_category/`)
            .then((res) => {
                if (res.status === 200) {
                    let data = res.data?.sort((a, b) => b.total_data - a.total_data) ?? [];
                    if (res.data.length >= 8)
                        data = data.slice(0, 8);
                    setCategory(data);
                }
            })
            .catch(err => {
                console.error("on getting categories", err.message);
                errorToast("Oups!")
            })
            .finally(() => setLoadingC(false));
    }


    // const valids = (item) => {
    //     return item.day_start - item.day_end !== 0 && item.day_end - item.day_start > 0;
    // }

    const getDate = (timestamps) => {
        var d = new Date(parseInt(timestamps) * 86400 * 1000);
        return d
    }
    const active = (item) => {
        var now = new Date();
        return (item.day_start - now <= 0) && (!item.day_end || now - item.day_end < 0);
    }

    const passed = (item) => {
        var now = new Date();
        let isValid = item.day_start - item.day_end <= 0 || !item.day_end;
        return isValid && (item.day_end && now - item.day_end >= 0);
    }

    const coming = (item) => {
        var now = new Date();
        let isValid = item.day_start - item.day_end <= 0 || !item.day_end;
        return isValid && (item.day_start && item.day_start - now >= 0);
    }

    const others = (item) => {
        var now = new Date();
        let isValid = item.day_start - item.day_end <= 0 || !item.day_end;
        let isActive = (item.day_start - now <= 0) && (!item.day_end || now - item.day_end < 0),
            isPassed = isValid && (item.day_end && now - item.day_end >= 0),
            isComing = isValid && (item.day_start && item.day_start - now >= 0);
        return !isActive && !isPassed && !isComing;
    }

    const getData = () => {
        axios.get(`https://d-portal.org/q?from=act,country&limit=-1&select=title,aid,funder_ref,commitment,spend,reporting,reporting_ref,day_start,day_end,status_code&distincton=aid&country_code=TD&view=list_activities&_=1689951820060`)
            .then(res => {
                let { rows = [] } = res.data;
                if (rows.length > 0) {
                    let data = rows.map(item => ({ ...item, day_start: getDate(item.day_start), day_end: item.day_end ? getDate(item.day_end) : null }));
                    setData(data);
                    // console.log([...new Set(data.filter(passed).map(item => item.title))].length, data.filter(passed).length);
                }
            })
            .catch(err => console.log("Error", err.message))
            .finally(() => setLoadingS(false))
    }

    // const getStats = () => {
    //     axios.get(BASE_URL + 'stats')
    //         .then(res => {
    //             if (res.status === 200)
    //                 setStats(res.data);
    //         })
    //         .catch(err => {
    //             errorToast('Oups! nous avons rencontrer un soucis, merci de réessayer plustard')
    //         })
    //         .finally(() => setLoadingS(false))
    // }

    const gettestimonies = () => {
        axios.get(BASE_URL + `testimony/`)
            .then((res) => {
                if (res.status === 200)
                    setTestimony(res.data);
            })
            .catch(err => {
                errorToast("Oups! nous avons rencontrer un soucis, merci de réessayer plustard")
            })
            .finally(() => setLoadingT(false));
    }

    useEffect(() => {
        getCategories();
        gettestimonies();
        getData();
    })


    // TRUNCATE LONG TEXT 
    const truncate = (input) =>
        input?.length > 300 ? `${input.substring(0, 254)}...` : input;
    return (
        <div>
            <div className="banner-area text-combo gradient-bg  bg-bottom-center top-pad-90 text-light" style={{ backgroundImage: "url('assets/img/shape/6.png')" }}>
                <div className="item">
                    <div className="box-table">
                        <div className="box-cell">
                            <div className="container">
                                <div className="row">
                                    <div className="double-items">
                                        <div className="col-lg-6 info">
                                            <h2 className="wow fadeInDown" data-wow-duration="1s"><span>Bienvenue sur la plateforme</span> Openstat Tchad</h2>
                                            <p className="wow fadeInLeft" data-wow-duration="1.5s">
                                                Votre outil de référence pour des données statistiques fiables et accessibles en un seul clic. Explorez notre vaste bibliothèque de données et visualisations pour comprendre les tendances et les enjeux clés au Tchad.
                                            </p>
                                            <p className="wow fadeInLeft" data-wow-duration="1.5s">
                                                Nous sommes ravis de vous accompagner dans votre recherche de connaissances et de vous offrir des outils pour prendre des décisions éclairées.
                                            </p>
                                            <a className="btn circle btn-md btn-light shape wow fadeInUp" data-wow-duration="1.8s" href="/category">Catégories</a>
                                        </div>
                                        <div className="col-lg-6 thumb wow fadeInRight" data-wow-duration="1s">
                                            <img src="assets/img/illustration/20.png" alt="Thumb" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="top-features default-padding text-center bottom-less">
                <div className="container">
                    {loadingC &&
                        <div className="col-12 d-flex justify-content-center">
                            <div className="spinner-border text-primary" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    <div className="row">
                        {categories.map((category, index) => {
                            const icon = category.icon ?? "flaticon-data";
                            return (
                                <div className="single-item col-lg-3 col-md-6" key={'cat-' + index}>
                                    <div className="item wow fadeInUp" data-wow-delay="400ms">
                                        <Link to={`/category/${category.slug}`}>
                                            <i className={icon}></i>
                                        </Link>
                                        <h4><Link to={`/category/${category.slug}`}>{category.name}</Link></h4>
                                        <p>
                                            {category.total_data} base{category.total_data > 1 ? 's' : ''} de donnée{category.total_data > 1 ? 's' : ''}
                                        </p>
                                    </div>
                                </div>
                            );
                        })
                        }
                        <div className='col-12 my-2'>
                            <a href='/category' className='btn circle btn-md btn-standard border-standard shape wow fadeInUp'>
                                {'Toutes les catégories'}
                            </a>
                        </div>
                    </div>
                </div>
                <div className="fixed-bg" style={{ backgroundImage: "url('assets/img/shape/9.svg')" }}></div>
                <div className="start-us-area overflow-hidden bg-gradient text-light default-padding">
                    <div className="fixed-bg" style={{ backgroundImage: 'url(assets/img/shape/1.png)' }}></div>
                    <div className="container">
                        <div className="row align-center">
                            <div className="col-lg-7">
                                <div className="info wow fadeInLeft">
                                    <h2>Demande de<br /> base de données</h2>
                                    <p>Veuillez vous connecter pour continuer</p>
                                    <a className="btn-standard md" href="/request/create"><i className="fas fa-angle-right"></i> Faire une demande</a>
                                </div>
                            </div>
                            <div className="col-lg-5">
                                <div className="thumb wow fadeInUp" data-wow-delay="400ms">
                                    <img src="assets/img/illustration/7.png" alt="Thumb" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div><br /><br />
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <div className="site-heading text-center">
                                <h4>{'Statistiques Projets au Tchad'}</h4>
                                {/* <h2>{'au Tchad'}</h2> */}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="single-item col-lg-3 col-md-6" key={'projet actives'}>
                            <div className="item border rounded circle wow fadeInUp" data-wow-delay="400ms">
                                <a href={'projets/active'}>
                                    <i className={'fas fa-rocket'}></i>
                                </a>
                                <h4><a href={'projets/active'}>{"Projets Actifs"}</a></h4>
                                <p className='w-100 project-count'>
                                    {loadingS ?
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> :
                                        <>
                                            {data.filter(active)?.length} <br />
                                        </>
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="single-item col-lg-3 col-md-6" key={'projets terminés'}>
                            <div className="item border rounded circle wow fadeInUp" data-wow-delay="400ms">
                                <a href={'projets/termines'}>
                                    <i className={'fas fa-calendar-check'}></i>
                                </a>
                                <h4><a href={'projets/termines'}>{"Projets terminés"}</a></h4>
                                <p className='w-100 project-count'>
                                    {loadingS ?
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> :
                                        <>
                                            {data.filter(passed)?.length} <br />
                                        </>
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="single-item col-lg-3 col-md-6" key={'projets avenir'}>
                            <div className="item border rounded circle wow fadeInUp" data-wow-delay="400ms">
                                <a href={'projets/avenir'}>
                                    <i className={'fas fa-spinner'}></i>
                                </a>
                                <h4><a href={'projets/avenir'}>{"Projets avenirs"}</a></h4>
                                <p className='w-100 project-count'>
                                    {loadingS ?
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> :
                                        <>
                                            {data.filter(coming)?.length} <br />
                                        </>
                                    }
                                </p>
                            </div>
                        </div>
                        <div className="single-item col-lg-3 col-md-6" key={'projets avenir'}>
                            <div className="item border rounded circle wow fadeInUp" data-wow-delay="400ms">
                                <a href={'projets/autres'}>
                                    <i className={'fas fa-ellipsis-h'}></i>
                                </a>
                                <h4><a href={'projets/autres'}>{"Autres"}</a></h4>
                                <p className='w-100 project-count'>
                                    {loadingS ?
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div> :
                                        <>
                                            {data.filter(others)?.length} <br />
                                        </>
                                    }
                                </p>
                            </div>
                        </div>
                        <div className='col-12 my-2'>
                            <a href='/projets/tous' className='btn circle btn-md btn-standard border-standard shape wow fadeInUp'>
                                <span style={{ textTransform: 'none' }}>
                                    {'Toutes les projets '}
                                </span>
                                {loadingS ?
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> :
                                    <>
                                        ({data.length})
                                    </>
                                }
                            </a>
                        </div>
                    </div>
                </div>
                {testimonies.length > 0 &&
                    <div style={{ backgroundImage: "url('assets/img/shape/9.svg')" }} className="fixed-bg testimonials-area default-padding-bottom">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-8 offset-lg-2">
                                    <div className="site-heading text-center">
                                        <h4>Témoignages</h4>
                                        <h2>Ce que les gens disent</h2>
                                    </div>
                                </div>
                            </div>
                            <div className="testimonial-items text-center">
                                {loadingT &&
                                    <div className="col-12 d-flex justify-content-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                }
                                <div className="row">
                                    <div className="col-lg-8 offset-lg-2">
                                        <div className="carousel slide" data-ride="carousel" id="testimonial-carousel">
                                            <div className="carousel-inner">
                                                {testimonies.map((testimony, index) => {
                                                    return (
                                                        <div key={'test-' + index} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                                                            <i className="ti-quote-left"></i>
                                                            <p>{truncate(testimony.description)}</p>
                                                            <h4>{testimony.full_name}</h4>
                                                            <span>{testimony.occupation}</span>
                                                        </div>
                                                    );
                                                })
                                                }

                                            </div>

                                            <ol className="carousel-indicators">
                                                {testimonies.map((testimony, index) => {
                                                    return (
                                                        <li key={'test-nav' + index} data-target="#testimonial-carousel" data-slide-to={index} className={index === 0 ? "active" : ""}>
                                                            <img src={testimony.image ?? 'assets/img/100x100.png'} alt={testimony.full_name} />
                                                        </li>
                                                    );
                                                })
                                                }
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}
export default Home 