import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import Moment from 'moment';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../Constant';
import { errorToast, successToast } from '../Constant/functions';


const BlogDetail = (propos) => {
    const { slug } = useParams();
    const [blogs, setBlog] = useState([]);
    const [blogData, setBlogData] = useState([]);
    const [blogcategories, setBlogCategorie] = useState([]);
    const [blogComments, setblogComments] = useState([]);
    const article_id = blogData.id
    // COMMENT 
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [comment, setComment] = useState('');
    const [isSubmetting, setIsSubmetting] = useState(false);

    const getBlog = (slug) => {
        axios.get(BASE_URL + `article/${slug}`)
            .then((res) => {
                if (res.status === 200)
                    setBlogData(res.data);
            })
    }


    const getBlogCategories = () => {
        axios.get(BASE_URL + `article_category/`)
            .then((res) => {
                if (res.status === 200)
                    setBlogCategorie(res.data);
            })
    }


    const getRecentBlogs = () => {
        axios.get(BASE_URL + `article/`)
            .then((res) => {
                if (res.status === 200)
                    setBlog(res.data);
            })
    }

    const getBlogComment = (slug) => {
        axios.get(BASE_URL + `article_comments/${slug}`)
            .then((res) => {
                if (res.status === 200)
                    setblogComments(res.data);
            })
    }


    function blogCommentReset() {
        setName('');
        setEmail('');
        setComment('');
    }

    useEffect(() => {
        getBlog(slug);
        getBlogCategories();
        getRecentBlogs();
        getBlogComment(slug);
    }, [slug])
    const onSubmit = () => {
        if (name === '') {
            errorToast('Nom est obligatoire !')
            return false;
        }
        if (email === '') {
            errorToast('Email est obligatoire !')
            return false;
        }
        if (comment === '') {
            errorToast('Commentaire est obligatoire !')
            return false;
        }
        if (name !== '' && email !== '' && comment !== '') {
            setIsSubmetting(true)
            const body = {
                article: article_id,
                name: name,
                email: email,
                comment: comment,
                active: true
            };

            axios.post(BASE_URL + `article_comments/`, body)
                .then((response) => {
                    if (response.status === 201) {
                        successToast('votre commentaire a été posté avec succes')
                        blogCommentReset()
                        getBlogComment()
                        setIsSubmetting(false)
                    }
                }).catch(err => {
                    errorToast("Oups... quelque chose s'est mal passé")
                    setIsSubmetting(false)
                });
        }
    }

    return (
        <div>
            
            <div className="breadcrumb-area gradient-bg text-light text-center">
                <div className="fixed-bg" style={{ backgroundImage: "url('assets/img/shape/1.png')" }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <h1>{blogData.name}</h1>
                            <ul className="breadcrumb">
                                <li><Link to={'/'}><i className="fas fa-home"></i> Accueil</Link></li>
                                <li><Link to={'/blog'}>Blog</Link></li>
                                <li className="active">Detail</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog-area single full-blog right-sidebar full-blog default-padding">
                <div className="container">
                    <div className="blog-items">
                        <div className="row">
                            <div className="blog-content col-lg-8 col-md-12">
                                <div className="item">

                                    <div className="blog-item-box">
                                        <div className="thumb">
                                            <a href="">
                                                <img src={blogData.photo} alt="Thumb" />
                                                <div className="date">
                                                    {Moment(blogData.timestamp).format('d MMM')} <strong>{Moment(blogData.timestamp).format('YYYY')}</strong>
                                                </div>
                                            </a>
                                        </div>

                                        <div className="info">
                                            <div className="meta">
                                                <ul>
                                                    <li><b>Categorie: </b><a href="">{blogData.category_name}</a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <h4>{blogData.name}</h4>
                                            <p>
                                                {blogData.description}
                                            </p>
                                        </div>
                                    </div>
                                </div>




                                <div className="blog-comments">
                                    <div className="comments-area">
                                        <div className="comments-title">
                                            <h4>
                                                {blogData.total_comment} commentaire{blogData.total_comment > 1 ? 's' : ''}
                                            </h4>
                                            <div className="comments-list">
                                                {blogComments.map((comment, index) => {
                                                    return (
                                                        <div className="commen-item" key={'comment-' + index}>
                                                            <div className="avatar">
                                                                <img src="assets/img/person.svg" alt={comment.name} />
                                                            </div>
                                                            <div className="content">
                                                                <div className="title">
                                                                    <h5>{comment.name}</h5>
                                                                    <span>{Moment(comment.timestamp).format('d MMM YYYY')}</span>
                                                                </div>
                                                                <p>{comment.comment}</p>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                                }
                                            </div>
                                        </div>
                                        <div className="comments-form">
                                            <div className="title">
                                                <h4>Laisser un commentaire</h4>
                                            </div>
                                            <form className="contact-comments">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input value={name} rows={2} onChange={(e) => setName(e.target.value)} className="form-control" placeholder="Nom *" type="text" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <input value={email} rows={2} onChange={(e) => setEmail(e.target.value)} className="form-control" placeholder="Email *" type="email" />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group comments">
                                                            <textarea  value={comment} rows={2} onChange={(e) => setComment(e.target.value)} className="form-control" placeholder="Commentaire"></textarea>
                                                        </div>
                                                        <div className="form-group full-width submit">
                                                            <button type="button" disabled={isSubmetting} onClick={onSubmit}>
                                                                Poster des commentaires
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="sidebar col-lg-4 col-md-12">
                                <aside>
                                    <div className="sidebar-item search">
                                        <div className="sidebar-info">
                                            <form>
                                                <input type="text" name="text" className="form-control" />
                                                <button type="submit"><i className="fas fa-search"></i></button>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="sidebar-item category">
                                        <div className="title">
                                            <h4>Catégories</h4>
                                        </div>
                                        <div className="sidebar-info">
                                            <ul>
                                                {blogcategories.map((category, index) => {
                                                    return (
                                                        <li key={'cat-' + index}>
                                                            <a href="">{category.name} <span>{category.total_article}</span></a>
                                                        </li>
                                                    );
                                                })
                                                }
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="sidebar-item recent-post">
                                        <div className="title">
                                            <h4>Post récent</h4>
                                        </div>
                                        <ul>
                                            {blogs.map((blog, index) => {
                                                return (
                                                    <li key={'recent-' + index}>
                                                        <div className="thumb">
                                                            <Link onClick={() => getBlog(blog.slug)}>
                                                                <img src={blog.photo} alt={blog.name} />
                                                            </Link>
                                                        </div>
                                                        <div className="info">
                                                            <Link onClick={() => getBlog(blog.slug)}>{blog.name}</Link>
                                                            <div className="meta-title">
                                                                <span className="post-date"><i className="fas fa-clock"></i> {Moment(blog.timestamp).format('d MMM YYYY')}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })
                                            }
                                        </ul>
                                    </div>


                                    <div className="sidebar-item social-sidebar">
                                        <div className="title">
                                            <h4>Suivez-nous</h4>
                                        </div>
                                        <div className="sidebar-info">
                                            <ul>
                                                <li className="facebook">
                                                    <a href="">
                                                        <i className="fab fa-facebook-f"></i>
                                                    </a>
                                                </li>
                                                <li className="twitter">
                                                    <a href="">
                                                        <i className="fab fa-twitter"></i>
                                                    </a>
                                                </li>
                                                <li className="pinterest">
                                                    <a href="">
                                                        <i className="fab fa-pinterest"></i>
                                                    </a>
                                                </li>
                                                <li className="g-plus">
                                                    <a href="">
                                                        <i className="fab fa-google-plus-g"></i>
                                                    </a>
                                                </li>
                                                <li className="linkedin">
                                                    <a href="">
                                                        <i className="fab fa-linkedin-in"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default BlogDetail