// import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Layout/Header';
import Footer from './Layout/Footer';
import Home from './components/Home';
import Category from './components/Category';
import Blog from './components/Blog';
import BlogDetail from './components/BlogDetail';
import Contact from './components/Contact';
import About from './components/About';
import Request from './components/Request';
import RequestCreate from './components/RequestCreate';
import Data from './components/Data';
import DataDetail from './components/DataDetail';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Auth from './components/Auth';
import Projects from './components/Projects';



function App() {
    return (
        <div className="App">
            <ToastContainer />
            <Header />
            <BrowserRouter>
                <Routes>
                    <Route path='/' element={<Home />} />
                    <Route path='/authentication' element={<Auth />} />
                    <Route path='/about' element={<About />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/blog' element={<Blog />} />
                    <Route path='/blog/:slug' element={<BlogDetail />} />
                    <Route path='/category' element={<Category />} />
                    <Route path='/category/:slug' element={<Data />} />
                    <Route path='/data/:slug' element={<DataDetail />} />
                    <Route path='/request' element={<Request />} />
                    <Route path='request/create' element={<RequestCreate />} />
                    <Route path='projets/:filter' element={<Projects />} />
                </Routes>
            </BrowserRouter>
            <Footer />
        </div>
    );
}

export default App;
