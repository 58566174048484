import axios from "axios";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AUTH_BASE_URL, BASE_URL } from '../Constant';
import { errorToast, successToast } from '../Constant/functions';



const Auth = () => {
    const [isSubmetting, setIsSubmetting] = useState(false);
    const [showSignInForm, setShowSignInForm] = useState(true);
    const [showSignUpForm, setShowSignUpForm] = useState(false);
    const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);

    // SING IN VARIABLES
    const [signinEmail, setSigninEmail] = useState('');
    const [signinPassword, setSigninPassword] = useState('');

    // SIGN UP VARIABLES 
    const [signUpEmail, setSignUpEmail] = useState('');
    const [signUpFirstName, setSignUpFirstName] = useState('');
    const [signUpLastName, setSignUpLastName] = useState('');
    const [signUpPassword, setSignUpPassword] = useState('');
    const [signUpPassword2, setSignUpPassword2] = useState('');

    // RESET PASSWORD VARIABLES 
    const [resetPassword, setResetPassword] = useState('');

    const string = localStorage.getItem('auth');
    const user = string ? JSON.parse(string) : {};
    let user_id = user?.id;
    if (user_id)
        window.location = `/request/create`

    const displaySignUpForm = () => {
        setShowSignInForm(false)
        setShowSignUpForm(true)
        setShowResetPasswordForm(false)
    }

    const displaySignInForm = () => {
        setShowSignInForm(true)
        setShowSignUpForm(false)
        setShowResetPasswordForm(false)
    }

    const displayResetPasswordForm = () => {
        setShowSignInForm(false)
        setShowSignUpForm(false)
        setShowResetPasswordForm(true)
    }

    function resetSignInForm() {
        setSigninEmail('');
        setSigninPassword('');
    }

    function resetSignUpForm() {
        setSignUpEmail('');
        setSignUpFirstName('');
        setSignUpLastName('');
        setSignUpPassword('');
        setSignUpPassword2('');
    }

    function resetForgotPasswordForm() {
        setResetPassword('');
    }



    const onSubmitSignIn = () => {
        if (signinEmail === '')
            return errorToast('Email est obligatoire !');
        else if (signinPassword === '')
            return errorToast('Mot de passe est obligatoire !');


        if (signinEmail !== '' && signinPassword !== '') {
            setIsSubmetting(true)
            const body = {
                email: signinEmail,
                password: signinPassword
            };
            axios.post(AUTH_BASE_URL + `login/`, body)
                .then((response) => {
                    if (response.status === 200) {
                        let jsondata = JSON.stringify(response.data);
                        localStorage.setItem('auth', jsondata)
                        successToast('Vous êtes connecté avec succès.')
                        resetSignInForm()
                        window.location.href = `/request/create`;
                    }
                }).catch(err => {
                    errorToast("Mauvais e-mail/mot de passe")

                })
                .finally(() => setIsSubmetting(false));

        }
    }

    const onSubmitSignUp = () => {
        if (signUpFirstName === '')
            return errorToast('Nom est obligatoire !');
        else if (signUpLastName === '')
            return errorToast('Prénom de passe est obligatoire !');
        else if (signUpEmail === '')
            return errorToast('Email est obligatoire !');
        else if (signUpPassword === '')
            return errorToast('Mot de passe est obligatoire !');
        else if (signUpPassword2 === '')
            return errorToast('Mot de passe est obligatoire !');
        else if (signUpPassword2 !== signUpPassword)
            return errorToast('Les mots de passe ne correspondent pas');
        if (signUpEmail !== '' && signUpPassword !== '') {
            setIsSubmetting(true)
            const body = {
                email: signUpEmail,
                first_name: signUpFirstName,
                last_name: signUpLastName,
                password: signUpPassword,
                password_confirmation: signUpPassword2
            };
            console.log("before the request", body);
            axios.post(AUTH_BASE_URL + `register/`, body)
                .then((response) => {
                    if (response.status === 201) {
                        let jsondata = JSON.stringify(response.data);
                        localStorage.setItem('auth', jsondata)
                        successToast('Votre compte a été créé avec succès')
                        resetSignUpForm()
                        window.location.href = `/request/create`;
                    }
                }).catch(err => {
                    if (err.response?.data) {
                        let { data = {} } = err.response;
                        Object.keys(data).forEach(error => {
                            if (data[error]) {
                                let errors = data[error];
                                errors.forEach(txt => {
                                    errorToast(txt);
                                })
                            }
                        });

                    } else {
                        errorToast("Oups... quelque chose s'est mal passé")
                    }
                })
                .finally(() => setIsSubmetting(false));

        }
    }


    const onSubmitResetPassword = () => {
        if (resetPassword === '')
            return errorToast('Email est obligatoire !');

        if (resetPassword !== '') {
            setIsSubmetting(true)
            const body = { email: resetPassword };
            axios.post(BASE_URL + `forgot-password/`, body)
                .then((response) => {
                    if (response.status === 201) {
                        successToast("L'e-mail vous a été envoyé, veuillez suivre les instructions.")
                        resetForgotPasswordForm()
                        setIsSubmetting(false)
                    }
                }).catch(err => {
                    errorToast("Oups... quelque chose s'est mal passé")
                    setIsSubmetting(false)
                });

        }
    }

    return (
        <div>
            <div className="breadcrumb-area gradient-bg text-light text-center">
                <div className="fixed-bg" style={{ backgroundImage: "url('assets/img/shape/1.png')" }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <h1>Connexion</h1>
                            <ul className="breadcrumb">
                                <li><Link to={'/'}><i className="fas fa-home"></i> Accueil</Link></li>
                                <li className="active">S'identifier</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="contact-area default-padding">
                <div className="container">
                    <div className="contact-items">
                        <div className="row align-items-center">
                            <div className="col-lg-4 left-item">
                                <div className="info-items">
                                    <div className="item align-items-center" onClick={() => displaySignInForm()}>
                                        <div className="icon">
                                            <i class="fas fa-sign-in-alt"></i>
                                        </div>
                                        <div className="info">
                                            <h6 className="text-center">S'identifier</h6>
                                        </div>
                                    </div>

                                    <div className="item align-items-center" onClick={() => displaySignUpForm()}>
                                        <div className="icon">
                                            <i class="fas fa-user-circle"></i>
                                        </div>
                                        <div className="info">
                                            <h6 className="text-center">S'inscrire</h6>
                                        </div>
                                    </div>

                                    <div className="item align-items-center" onClick={() => displayResetPasswordForm()}>
                                        <div className="icon">
                                            <i class="fas fa-lock-open"></i>
                                        </div>
                                        <div className="info">
                                            <h6 className="text-center">Mot de passe oublié</h6>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {showSignInForm &&
                                <div className="col-lg-8 right-item">
                                    <h2 className="text-center">{"Se connnecter"}</h2>
                                    <form className="contact-form">
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <input value={signinEmail} onChange={(e) => setSigninEmail(e.target.value)} className="form-control px-3 rounded bg-light" placeholder="Email *" type="email" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <input onChange={(e) => setSigninPassword(e.target.value)} className="form-control px-3 rounded bg-light" placeholder="Mot de passe *" type="password" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <button type="button" disabled={isSubmetting} onClick={onSubmitSignIn}>
                                                    S'identifier <i className="fa fa-paper-plane"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>}
                            {showSignUpForm &&
                                <div className="col-lg-8 right-item">
                                    <h2 className="text-center w-100">{"Créer un compte"}</h2>
                                    <form className="contact-form">
                                        <div className="row">
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <input value={signUpFirstName} onChange={(e) => setSignUpFirstName(e.target.value)} className="form-control px-3 rounded bg-light" placeholder="Nom *" type="text" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <input value={signUpLastName} onChange={(e) => setSignUpLastName(e.target.value)} className="form-control px-3 rounded bg-light" placeholder="Prénom *" type="text" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <input value={signUpEmail} onChange={(e) => setSignUpEmail(e.target.value)} className="form-control px-3 rounded bg-light" placeholder="Email *" type="email" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <input value={signUpPassword} onChange={(e) => setSignUpPassword(e.target.value)} className="form-control px-3 rounded bg-light" placeholder="Mot de passe *" type="password" />
                                                </div>
                                            </div>
                                            <div className="col-lg-12 mb-3">
                                                <div className="form-group">
                                                    <input value={signUpPassword2} onChange={(e) => setSignUpPassword2(e.target.value)} className="form-control px-3 rounded bg-light" placeholder="Confirmez le mot de passe *" type="password" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <button type="button" disabled={isSubmetting} onClick={onSubmitSignUp}>
                                                    S'inscrire <i className="fa fa-paper-plane"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>}
                            {showResetPasswordForm &&
                                <div className="col-lg-8 right-item">
                                    <h2>Réinitialiser le mot de passe</h2>
                                    <form className="contact-form">
                                        <div className="row">
                                            <div className="text-success">
                                                Entrez votre email et nous vous enverrons des structures à suivre
                                            </div>
                                            <div className="col-lg-12 mb-3 mt-5">
                                                <div className="form-group">
                                                    <input value={resetPassword} onChange={(e) => setResetPassword(e.target.value)} className="form-control" placeholder="Email *" type="email" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <button type="button" disabled={isSubmetting} onClick={onSubmitResetPassword}>
                                                    Réinitialiser <i className="fa fa-paper-plane"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Auth