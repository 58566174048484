import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import Moment from 'moment';
import { useParams } from 'react-router-dom';
import { BASE_URL } from '../Constant';
import { errorToast } from '../Constant/functions';

const Data = () => {
    const { slug } = useParams();
    const [categoryData, setCategoryData] = useState([]);
    const [categories, setCategory] = useState([]);
    const [loading, setLoading] = useState(true);


    const getCategoryData = (slug) => {
        axios.get(BASE_URL + `data_category/${slug}`)
            .then((res) => {
                if (res.status === 200)
                    setCategoryData(res.data);
            })
            .catch(err => {
                console.error("on getting categories", err.message);
                errorToast("Oups! nous avons rencontrer un soucis, réessayer.")
            })
            .finally(() => setLoading(false));
    }


    const getCategories = () => {
        axios.get(BASE_URL + `data_category/`)
            .then((res) => {
                if (res.status === 200)
                    setCategory(res.data);
            })
    }



    useEffect(() => {
        getCategoryData(slug);
        getCategories();
    }, [slug])

    // TRUNCATE LONG TEXT 
    const truncate = (input) =>
        input?.length > 300 ? `${input.substring(0, 254)}...` : input;

    return (
        <div>
            <div className="breadcrumb-area gradient-bg text-light text-center">
                <div className="fixed-bg" style={{ backgroundImage: "url('assets/img/shape/1.png')" }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <h1>{categoryData.name}</h1>
                            <ul className="breadcrumb">
                                <li><Link to="/"><i className="fas fa-home"></i> Accueil</Link></li>
                                <li><Link to={"/category"}>Catégories</Link></li>
                                <li className="active">Details</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div className="blog-area full-blog right-sidebar full-blog default-padding">
                <div className="container">
                    <div className="blog-items">
                        <div className="row">
                            <div className="blog-content col-lg-8 col-md-12">
                                {loading &&
                                    <div class="d-flex justify-content-center">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                }
                                {!loading && categoryData?.data?.length === 0 &&
                                    <h4> {'Aucune données pour le moment'}</h4>
                                }
                                <div className="blog-item-box">
                                    {categoryData?.data?.map((data, index) => {
                                        return (
                                            <div className="single-item" key={'data-' + index}>
                                                <div className="item">
                                                    <div className="info">
                                                        <div className="row align-items-center">
                                                            <div className="col-lg-4">
                                                                <Link to={`/data/${data.slug}`}>
                                                                    <img className='img-fluid' src={data.photo} alt={data.name} style={{ "width": "100%" }} />
                                                                </Link>
                                                            </div>
                                                            <div className="col-lg-8">
                                                                <h4>
                                                                    <Link to={`/data/${data.slug}`}>{data.name}</Link>
                                                                </h4>
                                                                <div className="meta">
                                                                    <ul>
                                                                        <li><b>Categorie: </b><Link to={'#'}>{data.category_name}</Link></li>
                                                                    </ul>
                                                                </div>
                                                                <p>{truncate(data.description)}</p><hr />
                                                                <div className="meta-title">
                                                                    <span className="post-date"><i className="fas fa-clock"></i> {Moment(data.timestamp).format('d MMM YYYY')}</span>
                                                                    <Link to={`/data/${data.slug}`} className='mx-5 my-3 fs-6 btn btn-primary rounded'>
                                                                        <small>Voir plus</small>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                    }
                                </div>
                            </div>

                            <div className="sidebar col-lg-4 col-md-12">
                                <aside>
                                    {/* <div className="sidebar-item search">
                                        <div className="sidebar-info">
                                            <form>
                                                <input type="text" name="text" className="form-control" />
                                                <button type="submit"><i className="fas fa-search"></i></button>
                                            </form>
                                        </div>
                                    </div> */}
                                    <div className="sidebar-item category">
                                        <div className="title">
                                            <h4>Catégories</h4>
                                        </div>
                                        <div className="sidebar-info">
                                            <ul>
                                                {categories.map((category, index) => {
                                                    return (
                                                        <li key={'cat-' + index} onClick={() => getCategoryData(category.slug)}>
                                                            <Link to={`/category/${category.slug}`}>{category.name} <span>{category.total_data}</span></Link>
                                                        </li>
                                                    );
                                                })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
export default Data