import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { BASE_URL } from '../Constant';
import { errorToast } from '../Constant/functions';

const Category = () => {
    const [categories, setCategory] = useState([]);
    const [loading, setLoading] = useState(true);

    const getCategories = () => {
        axios.get(BASE_URL + `data_category/`)
            .then((res) => {
                if (res.status === 200)
                    setCategory(res.data);
            })
            .catch(err => {
                console.error("on getting categories", err.message);
                errorToast("Oups! nous avons rencontrer un soucis, réessayer.")
            })
            .finally(() => setLoading(false));
    }

    useEffect(() => {
        getCategories();
    }, [])

    return (
        <div>
            <div className="breadcrumb-area gradient-bg text-light text-center">
                <div className="fixed-bg" style={{ backgroundImage: "url('assets/img/shape/1.png')" }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <h1>Catégories</h1>
                            <ul className="breadcrumb">
                                <li><Link to={'/'}><i className="fas fa-home"></i> Accueil</Link></li>
                                <li className="active">Catégories</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>



            <div className="top-features default-padding text-center bottom-less">
                <div className="container">
                    {loading &&
                        <div class="col-12 d-flex justify-content-center">
                            <div class="spinner-border text-primary" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    }
                    <div className="row">
                        {categories.map((category, index) => {
                            const icon = category.icon ?? "flaticon-data";
                            return (
                                <div className="single-item col-lg-3 col-md-6" key={'cat-' + index}>
                                    <div className="item wow fadeInUp" data-wow-delay="400ms">
                                        <Link to={`/category/${category.slug}`}>
                                            <i className={icon}></i>
                                        </Link>
                                        <h4><Link to={`/category/${category.slug}`}>{category.name}</Link></h4>
                                        <p>
                                            {category.total_data} base{category.total_data > 1 ? 's' : ''} de donnée{category.total_data > 1 ? 's' : ''}
                                        </p>
                                    </div>
                                </div>
                            );
                        })
                        }
                    </div>
                </div>
                <div className="fixed-bg" style={{ backgroundImage: "url('assets/img/shape/9.svg')" }}></div>

            </div>
        </div>
    )
}
export default Category