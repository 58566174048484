import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import Moment from 'moment';
import { BASE_URL } from '../Constant';



const Request = () => {
    const [requests, setRequest] = useState([]);

    const getReuqests = () => {
        // Star checking token
        let state = localStorage.getItem('auth');
        if (!state || state === null)
            window.location = `/authentication`;
        state = JSON.parse(state);
        if (!state?.id || state?.tokens === "" || state?.tokens === null)
            window.location = `/authentication`;

        let { access = '' } = state.tokens;

        if (access === '' || access === null)
            window.location = `/authentication`;
        // End checking

        axios.get(BASE_URL + `request/`, {
            headers: {
                Authorization: `Bearer ${access}`,
            }
        })
            .then((res) => {
                if (res.status === 200)
                    setRequest(res.data);
            })
            .catch(err => {
                console.log(err.message);
            })
    }

    useEffect(() => {
        getReuqests();
    }, [])


    return (

        <div>
            <div className="breadcrumb-area gradient-bg text-light text-center">
                <div className="fixed-bg" style={{ backgroundImage: "url('assets/img/shape/1.png')" }}></div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 offset-lg-2">
                            <h1>Demandes</h1>
                            <ul className="breadcrumb">
                                <li><Link to={'/'}><i className="fas fa-home"></i> Accueil</Link></li>
                                <li className="active">Demandes</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>



            <div className="blog-area full-blog blog-standard full-blog default-padding">
                <div className="container">
                    <div className="blog-items">
                        <div className="row">
                            <div className="blog-content col-lg-10 offset-lg-1 col-md-12">
                                <div className="blog-item-box">
                                    {requests.map((request) => {
                                        return (
                                            <div className="single-item" key={request.id}>
                                                <div className="item">
                                                    <div className="info">
                                                        <p>
                                                            <b className='text-primary'>{request.requested_by_full_name}:</b> {request.subject}
                                                        </p>
                                                        <div className="row">
                                                            <div className="col-lg-6">
                                                                <div className="meta">
                                                                    <ul>
                                                                        <li><b>Categorie: </b><b className='text-info'>{request.category_name}</b></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6">
                                                                <p className={request.status === "Réussi entièrement" ? 'text-success' : (request.status === "Information non détenue" ? 'text-warning' : (request.status === "En attente de traitement" ? 'text-info' : (request.status === "Réussi partiellement" ? 'text-primary' : 'text-danger')))}>{request.status}</p>
                                                            </div>
                                                        </div>

                                                        <div className="meta-title">
                                                            <span className="post-date"><i className="fas fa-clock"></i>{Moment(request.timestamp).format('D MMM YYYY')}</span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Request